import { computed, onMounted, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import SesmtService from '../../../services/SesmtService';
import { useStore } from 'vuex';

export function useFiltrosCliente(props, emit) {
    const router = useRouter();
    const store = useStore();
    const customersCombo = ref(null);

    const _filtrosExtras = computed({
        get() {
            return props.filtrosExtras;
        },
        set(value) {
            emit('update:filtrosExtras', value);
        }
    });

    async function filtrar() {
        await atualizarQueryParams();
        emit('loadCustomers');
    }

    async function atualizarQueryParams() {
        const { grupo, customer, status } = _filtrosExtras.value;
        store.dispatch('setQueryParamsTelaClientesGro', {
            grupo: grupo ? JSON.stringify(grupo.id) : undefined,
            customer: customer ? JSON.stringify(customer.id) : undefined,
            status: status || undefined
        });
        await router.replace({
            query: {
                grupo: grupo ? JSON.stringify(grupo.id) : undefined,
                customer: customer ? JSON.stringify(customer.id) : undefined,
                status: status || undefined
            }
        });
    }

    async function removerFiltros() {
        _filtrosExtras.value = {};
        await router.replace();
        emit('loadCustomers', true);
    }

    async function getCustomers(grupoId = null) {
        const { data } = await new SesmtService('/customers/combo').findAll({ filtrosExtras: { grupoId } });
        customersCombo.value = data;
    }

    watch(
        () => _filtrosExtras.value?.grupo?.id,
        async (newValue, oldValue) => {
            if (!newValue) {
                await getCustomers();
            }

            if (newValue && newValue !== oldValue) {
                _filtrosExtras.value.customer = null;
                await getCustomers(newValue);
            }
        },
        { deep: true }
    );

    onMounted(async () => {
        await getCustomers(_filtrosExtras.value?.grupo?.id);
    });

    return {
        customersCombo,
        _filtrosExtras,
        filtrar,
        removerFiltros
    };
}
